import styled from "styled-components";
import { Link as BaseLink } from '../../styles/';

export const Wrapper = styled.div`
background: #EAF4F4;
border-radius: 10px;
padding: 28px 26px;
display: flex;
flex-direction: column;
justify-content: space-between;
box-sizing: border-box;
min-height: 231px;
position: relative;
`;

export const Label = styled.label`
font-style: normal;
font-weight: bold;
font-size: 11px;
line-height: 120%;
color: #595959;
display: flex;
width: 100%;
`;

export const Title = styled.h4`
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 120%;
color: #18757B;
margin: 0;
`;
export const SubTitle = styled.h5`
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 120%;
color: #18757B;
margin: 0;
`;
export const Link = styled(BaseLink)`
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 120%;
`;

export const Button = styled.a`
text-decoration: none;
display: block;
padding: 6px 18px;
background: #18757B;
border-radius: 15px;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 120%;
display: flex;
align-items: center;
justify-items: center;
align-content: center;
justify-content: center;
text-align: center;
color: #FFFFFF;
align-self: flex-start;
transition: background .4s ease;
&:hover {
    background: #2CD6C1;
    color
}
`;

export const Mapa = styled.img`
position: absolute;
top: 22px;
right: 17px;
`;