import styled from "styled-components";
export const Wrapper = styled.div`
background: #F5F5F5;
box-sizing: border-box;
position: relative;
`;
export const Label = styled.label`
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 120%;
display: block;
cursor: pointer;
color: #2F2F2F;
padding: 16px 39px 12px 15px;
border: 1px solid #DDDDDD;
border-radius: 5px;
.open & {
    border-radius: 5px 5px 0 0;
}
`
export const Options = styled.div`
position: absolute;
top: 100%;
max-height: 0;
width: 100%;
overflow-x: hidden;
overflow-y: auto;
border-radius: 0 0 5px 5px;
z-index: 10;
&.open {
    border-bottom: 1px solid #DDDDDD;
}
> div {
    width: 100%;
    box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #18757B;
}

::-webkit-scrollbar-thumb:hover {
  background: #2CD6C1;
}
`;
export const List = styled.div`
display: flex;
flex-direction: column;
background: #F5F5F5;
`;
export const Option = styled.div`
width: 100%;
cursor: pointer;
border-bottom: 1px solid #DDDDDD;
box-sizing: border-box;
padding: 14px 15px;
transition: background .3s ease;
border-left: 1px solid #DDDDDD;
border-right: 1px solid #DDDDDD;
&:hover {
    background: #D5F3F0;
}
&:last-child {
    border-bottom: none;
}
`;

export const Icon = styled.span`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-content: center;
    justify-content: center;
`;