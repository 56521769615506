import styled from "styled-components";

export const Link = styled.a`
color: #18757B;
transition: color .4s ease;
text-decoration: underline;
word-break: break-all;

&:hover {
    color: #00B19C;
}
`;