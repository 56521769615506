import styled from "styled-components";
const Button = styled.button`
padding: 6px 15px;
background: #18757B;
border-radius: 15px;
font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 120%;
display: flex;
align-items: center;
text-align: center;
border: none;
color: #FFFFFF;
cursor: pointer;
transition: background .3s ease;
&[disabled] {
    background: #D2EFF1;
}
&:hover {
    background: #2CD6C1;
}
`;

export const SquareButton = styled.button`
cursor: pointer;
background: #18757B;
border-radius: 5px;
padding: 13px 23px;
color: #fff;
border: none;
cursor: pointer;
display: flex;
align-content: center;
align-items: center;
transition: background .3s ease;

.icon {
    margin-right: 16px;
}

&[disabled] {
    background: #D2EFF1;
}
&:hover {
    background: #2CD6C1;
}
`;
export default Button;